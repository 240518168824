<template>
  <div>
    <h2>Legal Notice</h2>
    
      <h4>Contact</h4>
      <p>
        mail : 
      </p>
      <h4>Hebergement</h4>
      <p>
        
      </p>
    
    
    
      <h4>Collecte des renseignements personnels</h4>
      <p>Nous collectons les renseignements suivants :</p>
      <ul>
         <li>Pseudonyme (username)</li>  
      </ul>

      <p>Les renseignements personnels que nous collectons sont recueillis au travers de formulaires et grâce à l'interactivité établie entre vous et notre site Web. </p>

      <br />
      <h4>Formulaires  et interactivité</h4>
      <p>Vos renseignements personnels sont collectés par le biais de formulaire, à savoir :</p>
      <ul>
          <li>Formulaire de renseignement</li>
      </ul>

      <p>Nous utilisons les renseignements ainsi collectés pour les finalités suivantes :</p>
      <ul>
          <li>Suivi du nombre de point et classement</li>
          <li>Support technique</li>
      </ul>

      <br />
      <h4>Droit d'opposition d'accès et de retrait</h4>
      <p>Nous nous engageons à vous offrir un droit d'opposition et de retrait quant à vos renseignements personnels.
      Le droit d'opposition s'entend comme étant la possiblité offerte aux internautes de refuser que leurs renseignements personnels soient utilisées à certaines fins mentionnées lors de la collecte.
        Le droit de retrait s'entend comme étant la possiblité offerte aux internautes de demander à ce que leurs renseignements personnels ne figurent plus, par exemple, dans une liste de diffusion.</p>
        <p>Nous nous engageons à reconnaître un droit d'accès et de rectification aux personnes concernées désireuses de consulter, modifier, voire radier les informations les concernant.</p>
      <p>Pour pouvoir exercer ces droits, vous pouvez nous contacter par mail</p>

      <br />
      <h4>Destinataires</h4>
      <p>Les données collectées sont destinées à notre service technique.</p>
      <p>Elles sont également transmises aux tiers suivants :</p>

      <ul>
          <li>Autres utilisateurs du site pour un classement</li>
      </ul>

      <br>

      <h3>Hébergement des données collectées</h3>
      <p>Les données collectées sont hébergées par les prestataires suivants : </p>
          <ul>
              <li>en qualité d'hébergeur du site web </li>
          </ul>

      <br />
      <h4>Sécurité</h4>
      <p>Les renseignements personnels que nous collectons sont conservés dans un environnement sécurisé. Les personnes travaillant pour nous sont tenues de respecter la confidentialité de vos informations.</p>

      <br />
      <h4>Vos droits sur les données vous concernant</h4>
      <p>
          Vous pouvez accéder et obtenir copie des données vous concernant, vous opposer au traitement de ces données, les faire rectifier ou les faire effacer.<br /> 
          Vous disposez également d'un droit à la limitation du traitement de vos données.<br /> 
          <a href="https://www.cnil.fr/fr/les-droits-pour-maitriser-vos-donnees-personnelles">Comprendre vos droits</a>
      </p>

      <br />
      <h4>Législation</h4>
      <p>Nous nous engageons à respecter les dispositions législatives énoncées dans : </p>
      <ul>
          <li><a href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees"> le Règlement Général sur la Protection des Données personnelles</a></li>
          <li><a href="https://www.cnil.fr/fr/loi-78-17-du-6-janvier-1978-modifiee">la loi « informatique et libertés »</a></li>

      </ul>
    
  </div>
  
  
  
</template>

<script>
export default {
  name: "legal-index",
  data() {
    return {
    }
  },
  methods: {
  
  },
  mounted() {

  }
};


</script>
